<template>
    <header class="bg-primary-gradient">
        <div class="container pt-4 pt-xl-5">
            <div class="row pt-5">
                <div class="col-md-8 col-xl-6 text-center text-md-start mx-auto">
                    <div class="text-center">
                        <p class="fw-bold text-dark mb-2">Your #1 choice for a Blissful Vacations</p>
                        <h1 class="fw-bold">Fun Filled Holidays at your Comfort<br /></h1>
                    </div>
                </div>
                <div class="col-12 col-lg-10 mx-auto">
                    <div class="d-flex position-relative" style="display: flex;flex-wrap: wrap;justify-content: flex-end;">
                        <div style="position: relative;flex: 0 0 45%;transform: translate3d(-15%, 35%, 0);">
                            <h3 class="text-center text-light d-flex imageOverText" data-bss-parallax-speed="0.4">Exotic Locations</h3><img class="img-fluid home-patch" data-bss-parallax data-bss-parallax-speed="0.2" src="@/assets/homeimgs/exotic-location.jpg" />
                        </div>
                        <div style="position: relative;flex: 0 0 45%;transform: translate3d(-5%, 20%, 0);">
                            <h3 class="text-center text-light d-flex imageOverText">Adventure Tours</h3><img class="img-fluid home-patch" data-bss-parallax data-bss-parallax-speed="0.2" src="@/assets/homeimgs/snorkeling-home.jpg" />
                        </div>
                        <div style="position: relative;flex: 0 0 60%;transform: translate3d(0, 0%, 0);">
                            <h3 class="text-center text-light d-flex imageOverText">Pilgrimage</h3><img class="img-fluid home-patch" data-bss-parallax data-bss-parallax-speed=".10" src="@/assets/homeimgs/pilgrim-kedarnath.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>