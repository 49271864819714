<template>
    <div class="container-fluid p-5 bg-dark">
        <div class="row mb-5">
            <div class="col-md-8 col-xl-6 text-center mx-auto">
                <h1 class="fw-bold text-warning mb-2">Reviews</h1>
                <h2 class="fw-bold text-light"><strong>What People Say About us</strong></h2>
                <p class="text-light ">No matter how far, we can take you there.</p>
            </div>
        </div>
        
        
            <Carousel :items-to-show="4.5" :wrap-around="true" :transition="500" :autoplay="2000" pauseAutoplayOnHover>
                <Slide v-for="r,index of reviews" :key="index" style="height:500px">
                    <testimonial class="carousel__item" :text=r.text :img=r.image 
                             :name=r.name :origin=r.origin >
                    </testimonial>
                </Slide>

                <template #addons>
                <Navigation />
                </template>
            </Carousel>
    </div>
</template>
<script>
import testimonial from './subs/testimonials.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
    components:{
        testimonial,Carousel,Slide,Pagination,Navigation,
    },
    data(){
        return{
            reviews:[
                {
                    name: "Munira Thanawala",
                    origin: "Google Reviews",
                    rating:5,
                    text: "we had planned trip to dubai with bliss holidays it was very good experience for us. The transportation and hotel was very well arranged. Drivers were all co-orperative and good. The hotels were close to the nearest tourist destination and local trains and buses too. The trip was very well executed",
                    image: "ficon.png"
                },
                {
                    name: "Nayana Madiwale",
                    origin: "Google Reviews",
                    rating:5,
                    text: "We had been to Nainital and Jim Corbett through Bliss Holidays. It was a wonderful experience, well planned and good arrangements of resorts by them. Prompt response and suggestions by them gives satisfaction to clients.",
                    image: "ficon.png"
                },
                {
                    name: "Terence D'Souza",
                    origin: "Google Reviews",
                    rating:5,
                    text: "Returned from a great enjoyable and relaxing holiday to Sikkim and Darjeeling, Bliss holidays took care of all arrangements, hotel and travel arrangements were great, provided us with good knowledgeable and experienced drivers .Definitely will recommend Bliss holidays",
                    image: "micon.png"
                },
                {
                    name: "Kunal Kawathekar",
                    origin: "Google Reviews",
                    rating:4.5,
                    text: "The dubai trip was awesome.. The trip represented great travel for the cost.. didn't like hotel bookings to the fullest.Thank you for a wonderful trip rest things were perfect!!",
                    image: "micon.png"
                },
            ]
        }
    }
}
</script>
<style scoped>
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>

